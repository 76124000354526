*,
::before,
::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

body {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.64;
  margin: 0;
  padding: 0;
  height: 100%;
  display: block;
}

.nav-link[profile="profile"] {
  background-color: #118ab2;
}

#regular-menu.fixed {
  position: fixed;
  top: 80px;
  border-radius: 3px;
  transition: border-radius 0.5s;
}

.nav-item.change-height {
  height: 55px;
}

/* BURGER BUTTON BARS ANIMATION */

/* Rotate first bar */
.open .bar-one {
  transform: rotate(-45deg) translate(-5px, 5px);
}

/* Fade out the second bar */
.open .bar-two {
  opacity: 0;
}

/* Rotate last bar */
.open .bar-three {
  transform: rotate(45deg) translate(-5px, -5px);
}
